import SEO from '../components/core/meta/SEO';
import React from 'react';
import 'twin.macro';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import SmallPartnerList from '../components/partials/PartnersList/SmallPartnerList';
import TwoPartLayout from '../components/partials/TwoPartLayout/TwoPartLayout';
import { WelfareTerritorioPageQuery } from '../../generated/graphql-types';
import BackgroundImage from 'gatsby-background-image';
import BrochureBanner from '../components/partials/BrochureBanner/BrochureBanner';
import ContactFormButton from '../components/partials/ContactFormButton/ContactFormButton';
import { listItemStyle } from './aziende-farmaceutiche';

const content = [
  'La popolazione italiana invecchia, la speranza di vita aumenta. Questo significa vivere più a lungo ma anche convivere per molti anni con una o più patologie croniche. Chi si occupa di assistenza - i Comuni, il territorio, i servizi sociali - sono chiamati a dare pronte ed efficienti risposte alle esigenze crescenti della popolazione, una sfida tutt’altro che facile.',
  'Ci piace lavorare con gli enti pubblici per garantire un supporto equo e concreto ai cittadini, raggiungendo le fasce più fragili.',
  'La collaborazione non è semplice perché in Italia, lo sappiamo, la burocrazia non aiuta e c’è ancora molto da fare per una buona sinergia pubblico-privato. Ma noi ci crediamo e perseveriamo, grazie anche a progetti realizzati come quello che segue.',
];

const patientSupportText = [
  'L’Azienda Farmaceutica Municipalizzata AFAM (Farmacie Comunali Firenze) è la prima rete di farmacie a trasformarsi in Società Benefit: si concretizza l’impegno ad operare in maniera responsabile, sostenibile e trasparente nei confronti di persone, comunità e ambiente.',
  'L’obiettivo è garantire a tutti l’accesso ai percorsi di cura più adatti. Per questo motivo si impegna ad offrire servizi dedicati di supporto alle terapie per le categorie più fragili con un bacino di iniziative per la presa in carico della persona fragile e anziana a 360° nel quotidiano e nella salute.',
  'Il Comune di Firenze insieme ad AFAM e UGO uniscono le forze per dar vita a un programma di supporto gratuito dedicato alle persone più fragili per età, salute ma anche dal punto di vista economico e sociale. I cittadini sono coinvolti dagli enti preposti sul territorio e scelgono se aderire: in caso affermativo, viene per loro attivato il servizio UGO gratuito.',
];

const patientSupportList = [
  {
    title: "L'impatto del servizio",
    list: [
      '+600 assistiti attivati dal 2021',
      '4.500 servizi erogati',
      '+10.000 ore di affiancamento',
      '+50 operatori caregiver attivati e formati sul territorio',
    ],
  },
];

const WelfareTerritorioPage = () => {
  const welfareTerritorioData = useStaticQuery<
    WelfareTerritorioPageQuery
  >(graphql`
    query WelfareTerritorioPage {
      headerBackground: file(relativePath: { eq: "b2b/headers/public.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      pharmacyImage: file(relativePath: { eq: "b2b/florence-pharmacy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO url={'/welfare-territorio'} title={'Welfare pubblico'} />
      <BackgroundImage
        fluid={welfareTerritorioData.headerBackground.childImageSharp.fluid}
      >
        <div
          tw="bg-black bg-opacity-60 py-16 flex"
          style={{ minHeight: '460px' }}
        >
          <Container tw="flex flex-col justify-center">
            <Heading variant="h2" tw="text-center text-white">
              {
                'Supportiamo la comunità locale collaborando con gli enti pubblici'
              }
            </Heading>
            <div tw="flex justify-center">
              <ContactFormButton />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <div tw="pt-16">
        <Container tw="mb-16">
          <div tw="mb-8">
            <h3 tw="text-h3-mobile md:text-h3 tracking-h3 font-bold mb-8">
              {
                'Aiutiamo la rete di servizi sul territorio per rispondere ai bisogni socio assistenziali delle fasce più fragili della popolazione. E funziona.'
              }
            </h3>
            {content.map((m, idx) => (
              <p
                key={idx}
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                {m}
              </p>
            ))}
          </div>
          <div tw="flex justify-center">
            <ContactFormButton />
          </div>
        </Container>
        <Container tw="mb-16">
          <SmallPartnerList
            partnerList={[
              'partner63',
              'partner66',
              'partner65',
              'partner62',
              'partner64',
            ]}
          />
        </Container>
        <TwoPartLayout
          imageFluid={welfareTerritorioData.pharmacyImage.childImageSharp.fluid}
          removeBottomMargin={true}
        >
          <h4 tw="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
            {
              'Le Farmacie Comunali di Firenze hanno scelto UGO per garantire l’accesso alle cure.'
            }
          </h4>
          {patientSupportText.map((t, idx) => (
            <p
              key={idx}
              style={{
                fontSize: '17px',
                lineHeight: '32px',
                marginBottom: '1rem',
              }}
            >
              {t}
            </p>
          ))}
          {patientSupportList.map(p => (
            <>
              <p
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                <div tw="mb-4">
                  <b>{p.title}</b>
                </div>
                <ol tw="list-disc" style={{ listStylePosition: 'inside' }}>
                  {p.list.map((l, index) => (
                    <li css={listItemStyle} key={index}>
                      {l}
                    </li>
                  ))}
                </ol>
              </p>
            </>
          ))}
        </TwoPartLayout>
        <BrochureBanner />
      </div>
    </>
  );
};

export default WelfareTerritorioPage;
